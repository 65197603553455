import {
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import {
  DataHandlerComponent,
  FlagList,
  LoadingBackdrop,
  PageLayout,
  ReadyToPublish,
  SortableTreeList,
} from "../../../components";
import { useGlobalStyles } from "../../../utils/theme";
import {
  ERouterPaths,
  IDialogContext,
  IMediaSelectDialogContext,
  INPUT_INTERACTIVE_TOUR_CONCLUSION_MAX_LENGTH,
  INPUT_INTERACTIVE_TOUR_DESCRIPTION_MAX_LENGTH,
  INPUT_INTERACTIVE_TOUR_IMPORTANT_INFO_MAX_LENGTH,
  INPUT_INTERACTIVE_TOUR_INTRODUCTION_MAX_LENGTH,
  INPUT_INTERACTIVE_TOUR_STARTING_POINT_MAX_LENGTH,
  INPUT_INTERACTIVE_TOUR_TITLE_MAX_LENGTH,
  MAIN_ISO_LANGUAGE_CODE,
  MSG_UNSAVED_CHANGES,
  TreeItem,
  TreeItems,
} from "../../../utils";
import { initialInputData } from "./InteractiveTourPage.inputs";
import {
  Add as AddIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
} from "@mui/icons-material";
import { ChevronLeftOutlined as ChevronLeftOutlinedIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  Reference,
  StoreObject,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  CREATE_INTERACTIVE_TOUR,
  CREATE_INTERACTIVE_TOUR_LOCALE,
  DELETE_CHECKPOINT,
  DELETE_INTERACTIVE_TOUR_LOCALE,
  ICreateInteractiveTourData,
  ICreateInteractiveTourLocaleData,
  ICreateInteractiveTourLocaleVars,
  ICreateInteractiveTourVars,
  IDeleteCheckpointData,
  IDeleteCheckpointVars,
  IDeleteInteractiveTourLocaleData,
  IDeleteInteractiveTourLocaleVars,
  IUpdateCheckpointOrderData,
  IUpdateCheckpointOrderVars,
  IUpdateInteractiveTourLocaleData,
  IUpdateInteractiveTourLocaleVars,
  IUpdateInteractiveTourOtherData,
  IUpdateInteractiveTourOtherVars,
  IUpdateInteractiveTourPublishedData,
  IUpdateInteractiveTourPublishedVars,
  UPDATE_CHECKPOINT_ORDER,
  UPDATE_INTERACTIVE_TOUR_LOCALE,
  UPDATE_INTERACTIVE_TOUR_OTHER,
  UPDATE_INTERACTIVE_TOUR_PUBLISHED,
} from "../../../apollo/mutations";
import { useNavigate } from "react-router";
import {
  ALL_DIFFICULTIES,
  ALL_INTERACTIVE_TOURS,
  ALL_TOUR_CATEGORIES,
  ICheckpoint,
  IDifficultiesData,
  IInteractiveTourDetailsData,
  IInteractiveTourDetailsVars,
  IInteractiveToursData,
  ITourCategoriesData,
  ONE_INTERACTIVE_TOUR_DETAILS,
} from "../../../apollo/queries";
import {
  MediaSelectButton,
  MediaSelectorDialog,
} from "../../../components/mediaSelectorContent/components";
import { DeleteDialog } from "../../../components";
import { useForm } from "../../../utils/hooks/useForm";
import { useSearchParams, useParams } from "react-router-dom";

export const InteractiveTourPage: FC = () => {
  const { classes: globalClasses } = useGlobalStyles();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    inputFields,
    validateForm,
    inputFieldNames,
    handleDataToVar,
    formError,
    getFormValuesFromFetchedData,
    activeLocale,
    setActiveLocale,
    currentLanguageFlags,
    setCurrentLanguageFlags,
    didValuesChange,
    resetFields,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const TOTAL_TABS = "3";
  const isEdit = id !== undefined;

  // const blocker = (currentLocation: Location, nextLocation: Location) => {
  //   if (
  //     didValuesChange(inputFieldNames) &&
  //     nextLocation.pathname !== currentLocation.pathname.split("?")[0]
  //   ) {
  //     const answer = window.confirm(
  //       "Are you sure you want to leave? Current changes will be lost."
  //     );
  //     if (answer) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  // unstable_useBlocker((history) =>
  //   blocker(history.currentLocation, history.nextLocation)
  // );

  const [activeTab, setActiveTab] = useState("1");
  const [checkPassedPublishTest, setCheckPassedPublishTest] = useState(false);
  const [orderedCheckpoints, setOrderedCheckpoints] = useState<TreeItems>([]);

  // const fetchDataDone = useRef(false);
  // unstable_usePrompt({
  //   when: didValuesChange(),
  //   message:
  //     "Are you sure you want to navigate away from this page? You have some unsaved changes!",
  // });

  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      type: undefined,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
    });
  const [checkpointDialogContext, setCheckpointDialogContext] =
    useState<IDialogContext>({
      open: false,
      type: undefined,
      id: undefined,
    });

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleCloseStationDialog = () => {
    setCheckpointDialogContext((prevState) => ({
      ...prevState,
      open: false,
      callback: undefined,
    }));
  };

  const {
    loading: loadingTourCategories,
    data: dataTourCategories,
    error: errorTourCategories,
  } = useQuery<ITourCategoriesData>(ALL_TOUR_CATEGORIES);

  const {
    loading: loadingDifficulties,
    data: dataDifficulties,
    error: errorDifficulties,
  } = useQuery<IDifficultiesData>(ALL_DIFFICULTIES);

  const [queryOneInteractiveTourDetails, { loading, data, error }] =
    useLazyQuery<IInteractiveTourDetailsData, IInteractiveTourDetailsVars>(
      ONE_INTERACTIVE_TOUR_DETAILS
    );

  //*---------------MUTATIONS

  const [
    createInteractiveTourMutation,
    { loading: loadingCreateInteractiveTourMutation },
  ] = useMutation<ICreateInteractiveTourData, ICreateInteractiveTourVars>(
    CREATE_INTERACTIVE_TOUR,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `New Interactive tour created: ${data.createInteractiveTour.locale.title}`,
          {
            variant: "success",
          }
        );
        // resetFields();
        navigate(`/${ERouterPaths.INTERACTIVE_TOURS}`);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Check if no tours
      update: (cache, { data }) => {
        const existingListData: IInteractiveToursData | null = cache.readQuery({
          query: ALL_INTERACTIVE_TOURS,
        });
        if (data?.createInteractiveTour) {
          //TODO: FIX
          const newTourData: any = {
            ...data.createInteractiveTour,
          };
          cache.writeQuery({
            query: ALL_INTERACTIVE_TOURS,
            data: {
              allInteractiveTours: existingListData?.allInteractiveTours
                ? [newTourData, ...existingListData.allInteractiveTours]
                : [newTourData],
            },
          });
        }
      },
    }
  );

  const [
    updateInteractiveTourLocaleMutation,
    { loading: loadingUpdateInteractiveTourLocaleMutation },
  ] = useMutation<
    IUpdateInteractiveTourLocaleData,
    IUpdateInteractiveTourLocaleVars
  >(UPDATE_INTERACTIVE_TOUR_LOCALE, {
    onCompleted: (data) => {
      enqueueSnackbar(
        `Locale updated: ${
          data.updateInteractiveTourLocale.title || "Nameless Interactive tour"
        }`,
        {
          variant: "success",
        }
      );
      resetFields(
        [
          "conclusion",
          "description",
          "gallery",
          "importantInfo",
          "introduction",
          "startingPoint",
          "title",
        ],
        true
      );
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    //TODO: Update!
    //? Not needed?
    // update: (cache, { data }) => {
    //   const existingListData: IToursData | null = cache.readQuery({
    //     query: ALL_TOURS,
    //   });
    //   if (data?.createTour) {
    //     const newTourData: ITour = {
    //       ...data.createTour,
    //     };
    //     cache.writeQuery({
    //       query: ALL_TOURS,
    //       data: {
    //         allTours: existingListData?.allTours
    //           ? [newTourData, ...existingListData.allTours]
    //           : [newTourData],
    //       },
    //     });
    //   }
    // },
  });

  const [
    deleteInteractiveTourLocaleMutation,
    { loading: loadingDeleteInteractiveTourLocaleMutation },
  ] = useMutation<
    IDeleteInteractiveTourLocaleData,
    IDeleteInteractiveTourLocaleVars
  >(DELETE_INTERACTIVE_TOUR_LOCALE, {
    onCompleted: (data) => {
      enqueueSnackbar("Interactive tour flag deleted", {
        variant: "success",
      });
      resetFields([
        "conclusion",
        "description",
        "gallery",
        "importantInfo",
        "introduction",
        "startingPoint",
        "title",
      ]);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.id !== data.deleteInteractiveTourLocale.localeId
      );
      setCurrentLanguageFlags(newCurrentFlags);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    update(cache, { data: dataCreate }) {
      //TODO: Fix. Cache is not linked to render
      if (data?.oneInteractiveTourDetails) {
        // console.log(cache);
        cache.modify({
          id: cache.identify(
            data.oneInteractiveTourDetails as unknown as StoreObject
          ),
          fields: {
            allLocales(existingData: Array<Reference>, { readField }) {
              if (data) {
                if (existingData && dataCreate) {
                  return existingData.filter(
                    (taskRef) =>
                      dataCreate.deleteInteractiveTourLocale.id !==
                      readField("id", taskRef)
                  );
                }
              }
            },
          },
        });
      }
    },
  });

  const handleDeleteTourLocale = () => {
    const localeId = data?.oneInteractiveTourDetails?.allLocales?.find(
      (x) => x.languageFlag.isoLanguageCode === activeLocale
    )?.id;
    if (localeId) {
      deleteInteractiveTourLocaleMutation({
        variables: {
          id: +localeId,
        },
      });
    } else {
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.isoLanguageCode !== activeLocale
      );
      resetFields([
        "conclusion",
        "description",
        "gallery",
        "importantInfo",
        "introduction",
        "startingPoint",
        "title",
      ]);
      setCurrentLanguageFlags(newCurrentFlags);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      enqueueSnackbar("Locale has been removed!", { variant: "success" });
    }
  };

  const [
    updateInteractiveTourPublishedMutation,
    { loading: loadingUpdateInteractiveTourPublishedMutation },
  ] = useMutation<
    IUpdateInteractiveTourPublishedData,
    IUpdateInteractiveTourPublishedVars
  >(UPDATE_INTERACTIVE_TOUR_PUBLISHED, {
    onCompleted: (data) => {
      if (!data.updateInteractiveTourPublished) {
        enqueueSnackbar(`Interactive tour is ready to be published!`, {
          variant: "info",
        });
        setCheckPassedPublishTest(true);
      } else {
        enqueueSnackbar(
          `Interactive tour is now ${
            data.updateInteractiveTourPublished.published
              ? "published"
              : "unpublished"
          }!`,
          {
            variant: "success",
          }
        );
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    //TODO: Update!
    //? Not needed?
    // update: (cache, { data }) => {
    //   const existingListData: IToursData | null = cache.readQuery({
    //     query: ALL_TOURS,
    //   });
    //   if (data?.createTour) {
    //     const newTourData: ITour = {
    //       ...data.createTour,
    //     };
    //     cache.writeQuery({
    //       query: ALL_TOURS,
    //       data: {
    //         allTours: existingListData?.allTours
    //           ? [newTourData, ...existingListData.allTours]
    //           : [newTourData],
    //       },
    //     });
    //   }
    // },
  });

  const [
    updateInteractiveTourOtherMutation,
    { loading: loadingUpdateInteractiveTourOtherMutation },
  ] = useMutation<
    IUpdateInteractiveTourOtherData,
    IUpdateInteractiveTourOtherVars
  >(UPDATE_INTERACTIVE_TOUR_OTHER, {
    onCompleted: (data) => {
      enqueueSnackbar("Other info updated", {
        variant: "success",
      });

      resetFields(["duration", "difficulty", "ageAppropriate"], true);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    //TODO: Update!
    //? Not needed?
    // update: (cache, { data }) => {
    //   const existingListData: IToursData | null = cache.readQuery({
    //     query: ALL_TOURS,
    //   });
    //   if (data?.createTour) {
    //     const newTourData: ITour = {
    //       ...data.createTour,
    //     };
    //     cache.writeQuery({
    //       query: ALL_TOURS,
    //       data: {
    //         allTours: existingListData?.allTours
    //           ? [newTourData, ...existingListData.allTours]
    //           : [newTourData],
    //       },
    //     });
    //   }
    // },
  });

  // const [updateTourStationMutation, { loading: loadingUpdateTourStation }] =
  //   useMutation<IUpdateTourStationData, IUpdateTourStationVars>(
  //     UPDATE_TOUR_STATION,
  //     {
  //       onCompleted: (data) => {
  //         // console.log({ data });
  //         enqueueSnackbar("Station list updated!", {
  //           variant: "success",
  //         });
  //       },
  //       onError: (error) => {
  //         enqueueSnackbar(error.message, {
  //           variant: "error",
  //         });
  //       },

  //       update: (cache, { data }) => {
  //         if (data?.updateTourStation) {
  //           // console.log(cache);
  //           cache.modify({
  //             id: cache.identify(
  //               data.updateTourStation as unknown as StoreObject
  //             ),
  //             fields: {
  //               allStations(existingData: Array<Reference>, { readField }) {
  //                 if (data) {
  //                   if (
  //                     existingData &&
  //                     data.updateTourStation &&
  //                     data.updateTourStation.allStations.length
  //                   ) {
  //                     // console.log({ existingData });
  //                     return [
  //                       readField(
  //                         "allStations",
  //                         data.updateTourStation as unknown as StoreObject
  //                       ),
  //                     ];
  //                   } else {
  //                     return [];
  //                   }
  //                 }
  //               },
  //             },
  //           });
  //         }
  //       },
  //     }
  //   );

  const [
    createInteractiveTourLocaleMutation,
    { loading: loadingCreateInteractiveTourLocaleMutation },
  ] = useMutation<
    ICreateInteractiveTourLocaleData,
    ICreateInteractiveTourLocaleVars
  >(CREATE_INTERACTIVE_TOUR_LOCALE, {
    onCompleted: (data) => {
      enqueueSnackbar(
        `Locale created: ${
          data.createInteractiveTourLocale.title || "Nameless Interactive tour"
        }`,
        {
          variant: "success",
        }
      );
      resetFields(
        [
          "conclusion",
          "description",
          "gallery",
          "importantInfo",
          "introduction",
          "startingPoint",
          "title",
        ],
        true
      );
    },

    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    update: (cache, { data: dataCreate }) => {
      // console.log(cache);
      if (data?.oneInteractiveTourDetails) {
        cache.modify({
          id: cache.identify(
            data.oneInteractiveTourDetails as unknown as StoreObject
          ),
          fields: {
            allLocales(existingData: Array<Reference>, { readField }) {
              if (data) {
                if (existingData && dataCreate) {
                  return [
                    ...existingData,
                    {
                      // @ts-ignore
                      __ref: `${dataCreate.createInteractiveTourLocale.__typename}:${dataCreate.createInteractiveTourLocale.id}`,
                    },
                  ];
                }
              }
            },
          },
        });
      }
    },
  });

  const [
    updateCheckpointOrderMutation,
    { loading: loadingUpdateCheckpointOrder },
  ] = useMutation<IUpdateCheckpointOrderData, IUpdateCheckpointOrderVars>(
    UPDATE_CHECKPOINT_ORDER,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Checkpoint order updated", {
          variant: "success",
        });
      },
      //TODO: Update!
      //? Not needed?
      // update: (cache, { data }) => {
      //   const existingListData: IToursData | null = cache.readQuery({
      //     query: ALL_TOURS,
      //   });
      //   if (data?.createTour) {
      //     const newTourData: ITour = {
      //       ...data.createTour,
      //     };
      //     cache.writeQuery({
      //       query: ALL_TOURS,
      //       data: {
      //         allTours: existingListData?.allTours
      //           ? [newTourData, ...existingListData.allTours]
      //           : [newTourData],
      //       },
      //     });
      //   }
      // },
    }
  );

  //*-----------------------
  useEffect(() => {
    if (id) {
      queryOneInteractiveTourDetails({ variables: { id: +id } });
    }
  }, [id, queryOneInteractiveTourDetails]);

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
    setSearchParams({ activeTab: newTab });
  };
  // console.log("NAVIGATION: ", navigation.location);

  const handleNextTab = () => {
    const nextTab = (parseInt(activeTab) + 1).toString();
    setActiveTab(nextTab);
    setSearchParams({ activeTab: nextTab });
  };

  const handlePrevTab = () => {
    const prevTab = (parseInt(activeTab) - 1).toString();
    setActiveTab(prevTab);
    setSearchParams({ activeTab: prevTab });
  };

  useEffect(() => {
    if (searchParams) {
      const getQueryTab = searchParams.get("activeTab");
      if (getQueryTab) {
        setActiveTab(getQueryTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateInteractiveTour = () => {
    if (validateForm(inputFieldNames, false)) {
      // const preparedStations: { stationId: number; order: number }[] =
      //   orderedStations.map((x, i) => {
      //     return { stationId: +x.id, order: i };
      //   });
      createInteractiveTourMutation({
        variables: {
          data: {
            duration: handleDataToVar("duration", "number", false),
            categoryId: undefined,
            // categoryId: handleDataToVar("category", "number", false),
            difficultyId: handleDataToVar("difficulty", "number", false),
            ageAppropriate: handleDataToVar("ageAppropriate", "string", false),
            //TODO: Handle this case
            // stations: preparedStations.length ? preparedStations : undefined,
          },
          dataLocale: {
            title: handleDataToVar("title", "string", false),
            description: handleDataToVar("description", "string", false),
            introduction: handleDataToVar("introduction", "string", false),
            conclusion: handleDataToVar("conclusion", "string", false),
            startingPoint: handleDataToVar("startingPoint", "string", false),
            importantInfo: handleDataToVar("importantInfo", "string", false),
            gallery: handleDataToVar("gallery", "numberArray", false),
          },
        },
      });
    }
  };

  useEffect(() => {
    //TODO: CHECK
    // if (fetchDataDone.current) {
    //   return;
    // }
    if (!data?.oneInteractiveTourDetails) {
      return;
    }

    getFormValuesFromFetchedData(data.oneInteractiveTourDetails, [
      {
        fromDataProperty: "allLocales.gallery.id",
        toFormProperty: "gallery",
      },
      { fromDataProperty: "duration", toFormProperty: "duration" },
      { fromDataProperty: "ageAppropriate", toFormProperty: "ageAppropriate" },
      // { fromDataProperty: "category.id", toFormProperty: "category" },
      { fromDataProperty: "difficulty.id", toFormProperty: "difficulty" },
      { fromDataProperty: "allLocales.title", toFormProperty: "title" },
      {
        fromDataProperty: "allLocales.description",
        toFormProperty: "description",
      },
      {
        fromDataProperty: "allLocales.introduction",
        toFormProperty: "introduction",
      },
      {
        fromDataProperty: "allLocales.conclusion",
        toFormProperty: "conclusion",
      },
      {
        fromDataProperty: "allLocales.startingPoint",
        toFormProperty: "startingPoint",
      },
      {
        fromDataProperty: "allLocales.importantInfo",
        toFormProperty: "importantInfo",
      },
    ]);

    if (data.oneInteractiveTourDetails.allCheckpoints?.length) {
      const prepareCheckpoints = (checkpoints: ICheckpoint[]): TreeItems =>
        checkpoints.map((checkpoint, i) => {
          const treeItem: TreeItem = {
            id: checkpoint.id,
            data: {
              published: checkpoint.published,
              title:
                checkpoint.locale.title ||
                `Unnamed Checkpoint - ${checkpoint.id}`,
            },
            children: [],
          };
          return treeItem;
        });
      const preparedData = prepareCheckpoints(
        data.oneInteractiveTourDetails.allCheckpoints
      );
      setOrderedCheckpoints(preparedData);
    }
    // fetchDataDone.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.oneInteractiveTourDetails]);

  useEffect(() => {
    // console.log("CURRENT INPUT FIELDS: ", inputFields);
  }, [inputFields]);

  const handleUpdateLocale = () => {
    if (
      !validateForm(
        [
          "title",
          "description",
          "introduction",
          "conclusion",
          "gallery",
          "startingPoint",
        ],
        !!data?.oneInteractiveTourDetails.published
      )
    ) {
      return null;
    }

    const getUpdateLocaleId = isEdit
      ? data?.oneInteractiveTourDetails.allLocales?.find(
          (x) => x.languageFlag?.isoLanguageCode === activeLocale
        )?.id
      : undefined;

    if (getUpdateLocaleId) {
      updateInteractiveTourLocaleMutation({
        variables: {
          id: +getUpdateLocaleId,
          data: {
            title: handleDataToVar("title", "string"),
            description: handleDataToVar("description", "string"),
            introduction: handleDataToVar("introduction", "string"),
            conclusion: handleDataToVar("conclusion", "string"),
            startingPoint: handleDataToVar("startingPoint", "string"),
            importantInfo: handleDataToVar("importantInfo", "string"),
            gallery: handleDataToVar("gallery", "numberArray"),
          },
        },
      });
    } else if (id) {
      createInteractiveTourLocaleMutation({
        variables: {
          interactiveTourId: +id,
          data: {
            title: handleDataToVar("title", "string", false),
            description: handleDataToVar("description", "string", false),
            introduction: handleDataToVar("introduction", "string", false),
            conclusion: handleDataToVar("conclusion", "string", false),
            startingPoint: handleDataToVar("startingPoint", "string", false),
            importantInfo: handleDataToVar("importantInfo", "string", false),
            gallery: handleDataToVar("gallery", "numberArray", false),
            isoLanguageCode: activeLocale,
          },
        },
      });
    } else {
      enqueueSnackbar("ID not found!", {
        variant: "error",
      });
    }
  };

  const handleUpdateOther = () => {
    if (
      !validateForm(
        [
          // "category",
          "duration",
          "difficulty",
          "ageAppropriate",
        ],
        data?.oneInteractiveTourDetails.published
      )
    ) {
      return null;
    }

    if (isEdit && id) {
      updateInteractiveTourOtherMutation({
        variables: {
          id: +id,
          data: {
            duration: handleDataToVar("duration", "number"),
            // categoryId: handleDataToVar("category", "number"),
            categoryId: undefined,
            difficultyId: handleDataToVar("difficulty", "number"),
            ageAppropriate: handleDataToVar("ageAppropriate", "string"),
          },
        },
      });
    } else {
      enqueueSnackbar("ID not found!", {
        variant: "error",
      });
    }
  };

  // const handleUpdateTourStation = () => {
  //   if (isEdit && id) {
  //     let iteration = 0;
  //     const prepareStations = (treeItems: TreeItems, parentId?: number): any =>
  //       treeItems
  //         .map((treeItem, i) => {
  //           const station: any = {
  //             id: +treeItem.id,
  //             order: iteration,
  //             parentId: parentId,
  //           };
  //           const subStations = treeItem.children ?? [];
  //           iteration++;

  //           if (subStations.length === 0) return station;

  //           const childrenStations = prepareStations(subStations, +treeItem.id);

  //           return [station, ...childrenStations];
  //         })
  //         .flat(10);

  //     const prepData = prepareStations(orderedStations);

  //     console.log(JSON.stringify(prepData, null, 2));

  //     updateInteractiveTourStationMutation({
  //       variables: {
  //         id: +id,
  //         data: {
  //           stations: prepData,
  //         },
  //       },
  //     });
  //   } else {
  //     enqueueSnackbar("ID not found!", {
  //       variant: "error",
  //     });
  //   }
  // };

  const handleUpdateTourPublished = () => {
    if (data && isEdit && id) {
      if (
        didValuesChange(inputFieldNames, true) &&
        !data.oneInteractiveTourDetails.published
      ) {
        enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
        return;
      }
      if (
        validateForm(
          inputFieldNames,
          !data.oneInteractiveTourDetails.published,
          true
        )
      ) {
        updateInteractiveTourPublishedMutation({
          variables: {
            id: +id,
            publish: data?.oneInteractiveTourDetails.published ? false : true,
            checkOnly:
              !data?.oneInteractiveTourDetails.published &&
              !checkPassedPublishTest,
          },
        });
      }
    } else {
      enqueueSnackbar("ID not found!", {
        variant: "error",
      });
    }
  };

  const [
    deleteCheckpointMutation,
    { loading: loadingDeleteCheckpointMutation },
  ] = useMutation<IDeleteCheckpointData, IDeleteCheckpointVars>(
    DELETE_CHECKPOINT,
    {
      onCompleted: (res) => {
        enqueueSnackbar("Checkpoint deleted!", { variant: "success" });
        handleCloseStationDialog();
        if (checkpointDialogContext.callback) {
          checkpointDialogContext.callback();
        }
      },
      onError: (error) => {
        // console.log({ error });
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Does this even work?
      update(cache, { data }) {
        cache.modify({
          fields: {
            allCheckpoints(existingStations: Array<Reference>, { readField }) {
              if (data) {
                return existingStations.filter(
                  (taskRef) =>
                    data.deleteCheckpoint.id !== readField("id", taskRef)
                );
              }
            },
          },
        });
      },
    }
  );

  const handleRemoveOrderedCheckpoint = () => {
    if (
      orderedCheckpoints.length &&
      checkpointDialogContext.id &&
      checkpointDialogContext.type === "delete"
    ) {
      deleteCheckpointMutation({
        variables: { id: +checkpointDialogContext.id },
      });
      const newOrderedStations = orderedCheckpoints.filter(
        (x) => x.id !== checkpointDialogContext.id
      );
      setOrderedCheckpoints(newOrderedStations);
    }
    //   handleCloseStationDialog();
    //   enqueueSnackbar("Checkpoint disconnected", {
    //     variant: "success",
    //   });
    //   if (checkpointDialogContext.callback) {
    //     checkpointDialogContext.callback();
    //   }
    // } else {
    //   enqueueSnackbar("Checkpoint not found", {
    //     variant: "error",
    //   });
    // }
  };

  const handleCreateNew = () => {
    if (didValuesChange()) {
      enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
      return;
    }
    navigate("new");
    // setStationDialogContext({
    //   open: true,
    //   id: undefined,
    //   type: "create",
    // });
  };

  const handleDelete = (id: string, callback: () => void) => {
    setCheckpointDialogContext({
      open: true,
      id: id,
      type: "delete",
      callback: callback,
    });
  };

  const handleEdit = (id: string) => {
    navigate(id);
  };

  const handleReorderCheckpointMutation = (
    beforeId: string | undefined,
    toId: string,
    nextId: string | undefined,
    parentId: string | null,
    callback?: () => void
  ) => {
    updateCheckpointOrderMutation({
      variables: {
        beforeId: beforeId ? +beforeId : undefined,
        toId: +toId,
        nextId: nextId ? +nextId : undefined,
      },
      onCompleted: () => {
        if (callback) {
          callback();
        }
      },
      onError: (err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      },
    });
  };

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="General" value="1" />
          <Tab label="Other" value="2" />
          <Tab label="Checkpoints" value="3" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div className={globalClasses.paperTitle}>
            <div className={globalClasses.justifySpaceBetween}>
              <Typography variant="h6">
                {isEdit
                  ? "Edit Interactive tour"
                  : "Add a new Interactive tour"}
              </Typography>
              {isEdit && data?.oneInteractiveTourDetails ? (
                <ReadyToPublish
                  handlePublish={handleUpdateTourPublished}
                  checkPassed={checkPassedPublishTest}
                  published={data.oneInteractiveTourDetails.published}
                  publishedThingText="Interactive tour"
                />
              ) : null}
            </div>
          </div>
          <DataHandlerComponent
            hasData={Boolean(
              (!isEdit || (isEdit && data?.oneInteractiveTourDetails)) &&
                dataDifficulties?.allDifficulties?.length &&
                dataTourCategories?.allTourCategories?.length
            )}
            error={Boolean(
              error || formError || errorDifficulties || errorTourCategories
            )}
            loading={loading || loadingTourCategories || loadingDifficulties}
          >
            <div className={globalClasses.paperContainer}>
              <TabPanel value="1" className={globalClasses.tabPanel}>
                {!isEdit ? (
                  <Typography color="textSecondary">
                    Add the first details about the Interactive tour you want to
                    create.
                  </Typography>
                ) : null}
                {/* //TODO: Make on top */}
                <FlagList
                  deleteLocaleFlagMutation={handleDeleteTourLocale}
                  canSelectFlags={isEdit}
                  activeLocale={activeLocale}
                  setActiveLocale={setActiveLocale}
                  currentLanguageFlags={currentLanguageFlags}
                  setCurrentLanguageFlags={setCurrentLanguageFlags}
                  type="Interactive tour"
                  tooltip='New translations for Interactive tours and Checkpoints will only be visible to users if you have already added them in the "About us" section.'
                />

                <TextField
                  margin="normal"
                  {...inputFields.title.inputProps}
                  autoFocus
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_INTERACTIVE_TOUR_TITLE_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.title.inputProps.value.length}/${INPUT_INTERACTIVE_TOUR_TITLE_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={8}
                  pickType="image"
                  {...inputFields.gallery.inputProps}
                />

                <TextField
                  margin="normal"
                  {...inputFields.description.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_INTERACTIVE_TOUR_DESCRIPTION_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.description.inputProps.value.length}/${INPUT_INTERACTIVE_TOUR_DESCRIPTION_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  margin="normal"
                  {...inputFields.introduction.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_INTERACTIVE_TOUR_INTRODUCTION_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.introduction.inputProps.value.length}/${INPUT_INTERACTIVE_TOUR_INTRODUCTION_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  margin="normal"
                  {...inputFields.conclusion.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_INTERACTIVE_TOUR_CONCLUSION_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.conclusion.inputProps.value.length}/${INPUT_INTERACTIVE_TOUR_CONCLUSION_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  margin="normal"
                  {...inputFields.startingPoint.inputProps}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_INTERACTIVE_TOUR_STARTING_POINT_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.startingPoint.inputProps.value.length}/${INPUT_INTERACTIVE_TOUR_STARTING_POINT_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  margin="normal"
                  {...inputFields.importantInfo.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_INTERACTIVE_TOUR_IMPORTANT_INFO_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.importantInfo.inputProps.value.length}/${INPUT_INTERACTIVE_TOUR_IMPORTANT_INFO_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </TabPanel>
              <TabPanel className={globalClasses.tabPanel} value="2">
                {!isEdit ? (
                  <Typography color="textSecondary">
                    Add additional content to your Interactive tour.
                  </Typography>
                ) : null}
                <div className={globalClasses.flexWrapContainer}>
                  <TextField
                    margin="normal"
                    {...inputFields.duration.inputProps}
                    className={globalClasses.inputSmallFlex}
                    autoFocus
                    inputProps={{}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="caption">minutes</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <TextField
                    {...inputFields.category.inputProps}
                    margin="normal"
                    className={globalClasses.inputSmallFlex}
                    select
                  >
                    <MenuItem value="">-- Choose option --</MenuItem>

                    {dataTourCategories?.allTourCategories?.length ? (
                      dataTourCategories.allTourCategories.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.locale.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>Loading...</MenuItem>
                    )}
                  </TextField> */}

                  <TextField
                    {...inputFields.difficulty.inputProps}
                    margin="normal"
                    className={globalClasses.inputSmallFlex}
                    select
                  >
                    <MenuItem value="">-- Choose option --</MenuItem>
                    {dataDifficulties?.allDifficulties?.length ? (
                      dataDifficulties.allDifficulties.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.locale.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>Loading...</MenuItem>
                    )}
                  </TextField>
                  <TextField
                    {...inputFields.ageAppropriate.inputProps}
                    margin="normal"
                    className={globalClasses.inputSmallFlex}
                    select
                  >
                    <MenuItem value="">-- Choose option --</MenuItem>
                    <MenuItem value={"18+"} key={"18+"}>
                      18+
                    </MenuItem>
                    <MenuItem value={"16+"} key={"16+"}>
                      16+
                    </MenuItem>
                    <MenuItem value={"12+"} key={"12+"}>
                      12+
                    </MenuItem>
                    <MenuItem value={"8+"} key={"8+"}>
                      8+
                    </MenuItem>
                    <MenuItem value={"4+"} key={"4+"}>
                      4+
                    </MenuItem>
                  </TextField>
                </div>
              </TabPanel>
              <TabPanel className={globalClasses.tabPanel} value="3">
                <div className={globalClasses.justifySpaceBetween}>
                  {!isEdit ? (
                    <>
                      <Typography color="textSecondary">
                        You can create Checkpoints here once you create your
                        Interactive tour.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography color="textSecondary">
                        Create, edit, delete or reorder Checkpoints here.
                      </Typography>

                      <Button
                        startIcon={<AddIcon />}
                        onClick={handleCreateNew}
                        variant="outlined"
                      >
                        Add new Checkpoint
                      </Button>
                    </>
                  )}
                </div>
                {isEdit ? (
                  <SortableTreeList
                    loading={loading}
                    collapsible
                    indicator
                    orderedList={orderedCheckpoints}
                    setOrderedList={setOrderedCheckpoints}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    onReorder={handleReorderCheckpointMutation}
                  />
                ) : null}
              </TabPanel>
            </div>
            <div className={globalClasses.paperButtons}>
              <div className={globalClasses.paperButtonsLeft}>
                {activeTab !== "1" ? (
                  <Button
                    onClick={handlePrevTab}
                    variant="outlined"
                    color="inherit"
                    startIcon={<ChevronLeftOutlinedIcon />}
                  >
                    Previous
                  </Button>
                ) : null}
              </div>
              <div className={globalClasses.paperButtonsRight}>
                {activeTab !== TOTAL_TABS ? (
                  <Button
                    onClick={handleNextTab}
                    variant="outlined"
                    endIcon={<ChevronRightOutlinedIcon />}
                  >
                    Next
                  </Button>
                ) : activeTab === TOTAL_TABS && !isEdit ? (
                  <Button
                    onClick={handleCreateInteractiveTour}
                    variant="contained"
                  >
                    Finish creating Interactive tour
                  </Button>
                ) : null}

                {activeTab === "1" && isEdit ? (
                  <Button
                    disabled={
                      !didValuesChange([
                        "conclusion",
                        "description",
                        "gallery",
                        "importantInfo",
                        "introduction",
                        "startingPoint",
                        "title",
                      ]) &&
                      !!data?.oneInteractiveTourDetails.allLocales?.find(
                        (x) => x.languageFlag?.isoLanguageCode === activeLocale
                      )?.id
                    }
                    onClick={handleUpdateLocale}
                    variant="contained"
                  >
                    {!data?.oneInteractiveTourDetails.allLocales?.find(
                      (x) => x.languageFlag?.isoLanguageCode === activeLocale
                    )?.id
                      ? "create "
                      : "update "}
                    | {activeLocale} |
                  </Button>
                ) : null}
                {activeTab === "2" && isEdit ? (
                  <Button
                    disabled={
                      !didValuesChange([
                        "difficulty",
                        "duration",
                        "ageAppropriate",
                      ])
                    }
                    onClick={handleUpdateOther}
                    variant="contained"
                  >
                    Update
                  </Button>
                ) : null}
              </div>
            </div>
          </DataHandlerComponent>
        </Paper>
      </TabContext>
      <LoadingBackdrop
        loading={
          loadingCreateInteractiveTourMutation ||
          loadingUpdateInteractiveTourLocaleMutation ||
          loadingCreateInteractiveTourLocaleMutation ||
          loadingUpdateInteractiveTourOtherMutation ||
          loadingUpdateCheckpointOrder ||
          loadingUpdateInteractiveTourPublishedMutation ||
          loadingDeleteInteractiveTourLocaleMutation ||
          loadingDeleteCheckpointMutation
        }
      />
      <MediaSelectorDialog
        dialogContext={mediaSelectDialogOpen}
        onClose={handleCloseMediaSelectDialog}
      />
      <DeleteDialog
        open={
          checkpointDialogContext.type === "delete" &&
          checkpointDialogContext.open
        }
        title="Delete this Checkpoint?"
        description="This action is permanent!"
        buttonTitle="Delete"
        onClose={handleCloseStationDialog}
        mutation={handleRemoveOrderedCheckpoint}
      />
    </PageLayout>
  );
};
