import { gql } from "@apollo/client";

export interface ISalesData {
  getSales: number;
}

export interface ISalesVars {
  endDate: Date;
  startDate: Date;
}

export const ALL_SALES = gql`
  query getSales($startDate: DateTime!, $endDate: DateTime!) {
    getSales(startDate: $startDate, endDate: $endDate)
  }
`;
