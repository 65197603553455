import { gql } from "@apollo/client";

export interface IStatisticsSubjectData {
  getStatisticsSubject: number;
}

export interface IStatisticsSubjectVars {
  endDate: Date;
  startDate: Date;
}

export const GET_STATISTICS_SUBJECT = gql`
  query getStatisticsSubject($startDate: DateTime!, $endDate: DateTime!) {
    getStatisticsSubject(startDate: $startDate, endDate: $endDate)
  }
`;
