import { FC, useContext } from "react";
import {
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ContextProvider, ERoleAccess, ERouterPaths } from "../utils";
import { Header, Navbar } from "../components";
import {
  AdministratorsPage,
  ClassicToursListPage,
  ForgotPasswordPage,
  LoginPage,
  NotFoundPage,
  ClassicTourPage,
  MediaPage,
  AboutUsPage,
  StationPage,
  ResetPasswordPage,
  InteractiveToursListPage,
  InteractiveTourPage,
  CheckpointPage,
  GuidelinesPage,
  ProductListPage,
  ProductPage,
  ReceptionPage,
  ReceiptListPage,
  ValidatePersonPage,
  JoinPage,
  SalesPage,
} from "./";
import { StatisticsPage } from "./private/statisticsPage/StatisticsPage";

interface IProps {
  children?: React.ReactNode;
  roles?: ERoleAccess[];
}

const PrivateOutlet: FC<IProps> = (props) => {
  const { children, roles } = props;
  const { user } = useContext(ContextProvider);
  // console.log("USER: ", user);

  if (user && user.id && user.activePersonRoleSubject?.id) {
    if (!roles || roles.includes(user.activePersonRoleSubject.role.access)) {
      return (
        <>
          {children}
          <Outlet />
        </>
      );
    }
    return <NotFoundPage />;
  }
  return <Navigate to="/login" replace />;
};

const PublicOutlet: FC<IProps> = ({ children }) => {
  const { user } = useContext(ContextProvider);

  return !user ? (
    <>
      {children}
      <Outlet />
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export const MainRouter = () => {
  const { user } = useContext(ContextProvider);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={
            <>
              <Header />
              {user ? <Navbar /> : null}
              <Outlet />
            </>
          }
        >
          <Route
            index
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <Navigate to={ERouterPaths.CLASSIC_TOURS} />
              </PrivateOutlet>
            }
          />
          <Route
            path={ERouterPaths.INTERACTIVE_TOURS}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <InteractiveToursListPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={`${ERouterPaths.INTERACTIVE_TOURS}/new`}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <InteractiveTourPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={`${ERouterPaths.INTERACTIVE_TOURS}/:id`}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <InteractiveTourPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={`${ERouterPaths.INTERACTIVE_TOURS}/:id/new`}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <CheckpointPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={`${ERouterPaths.INTERACTIVE_TOURS}/:id/:checkpointId`}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <CheckpointPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={ERouterPaths.CLASSIC_TOURS}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <ClassicToursListPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={`${ERouterPaths.CLASSIC_TOURS}/new`}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <ClassicTourPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={`${ERouterPaths.CLASSIC_TOURS}/:classicTourId`}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <ClassicTourPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={`${ERouterPaths.CLASSIC_TOURS}/:classicTourId/new`}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <StationPage />
              </PrivateOutlet>
            }
          />

          <Route
            path={`${ERouterPaths.CLASSIC_TOURS}/:classicTourId/:stationId`}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <StationPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={ERouterPaths.MEDIA}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                ]}
              >
                <MediaPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={ERouterPaths.ABOUT_US}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                ]}
              >
                <AboutUsPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={ERouterPaths.ADMINISTRATORS}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                ]}
              >
                <AdministratorsPage />
              </PrivateOutlet>
            }
          />

          {user?.activePersonRoleSubject?.subject.businessModel?.canSell ? (
            <>
              <Route
                path={ERouterPaths.PRODUCTS}
                element={
                  <PrivateOutlet
                    roles={[
                      ERoleAccess.SUPER_ADMIN,
                      ERoleAccess.OWNER,
                      ERoleAccess.MANAGER,
                    ]}
                  >
                    <ProductListPage />
                  </PrivateOutlet>
                }
              />
              <Route
                path={`${ERouterPaths.PRODUCTS}/new`}
                element={
                  <PrivateOutlet
                    roles={[
                      ERoleAccess.SUPER_ADMIN,
                      ERoleAccess.OWNER,
                      ERoleAccess.MANAGER,
                    ]}
                  >
                    <ProductPage />
                  </PrivateOutlet>
                }
              />
              <Route
                path={`${ERouterPaths.PRODUCTS}/:id`}
                element={
                  <PrivateOutlet
                    roles={[
                      ERoleAccess.SUPER_ADMIN,
                      ERoleAccess.OWNER,
                      ERoleAccess.MANAGER,
                    ]}
                  >
                    <ProductPage />
                  </PrivateOutlet>
                }
              />
              <Route
                path={ERouterPaths.RECEPTION}
                element={
                  <PrivateOutlet
                    roles={[
                      ERoleAccess.SUPER_ADMIN,
                      ERoleAccess.OWNER,
                      ERoleAccess.MANAGER,
                      ERoleAccess.ADMIN_PLUS,
                      ERoleAccess.RECEPTION,
                    ]}
                  >
                    <ReceptionPage />
                  </PrivateOutlet>
                }
              />
              <Route
                path={ERouterPaths.RECEIPTS}
                element={
                  <PrivateOutlet
                    roles={[
                      ERoleAccess.SUPER_ADMIN,
                      ERoleAccess.OWNER,
                      ERoleAccess.MANAGER,
                      ERoleAccess.ADMIN_PLUS,
                      ERoleAccess.RECEPTION,
                    ]}
                  >
                    <ReceiptListPage />
                  </PrivateOutlet>
                }
              />
              {/* <Route
                path={ERouterPaths.SALES}
                element={
                  <PrivateOutlet
                    roles={[
                      ERoleAccess.SUPER_ADMIN,
                      ERoleAccess.OWNER,
                      ERoleAccess.MANAGER,
                    ]}
                  >
                    <SalesPage />
                  </PrivateOutlet>
                }
              /> */}
            </>
          ) : null}

          {user?.activePersonRoleSubject?.subject.businessModel
            ?.hasStatistics ? (
            <>
              <Route
                path={ERouterPaths.STATISTICS}
                element={
                  <PrivateOutlet
                    roles={[
                      ERoleAccess.SUPER_ADMIN,
                      ERoleAccess.OWNER,
                      ERoleAccess.MANAGER,
                      ERoleAccess.ADMIN_PLUS,
                      ERoleAccess.ADMIN,
                      ERoleAccess.RECEPTION,
                    ]}
                  >
                    <StatisticsPage />
                  </PrivateOutlet>
                }
              />
            </>
          ) : null}

          <Route
            path={ERouterPaths.GUIDELINES}
            element={
              <PrivateOutlet
                roles={[
                  ERoleAccess.SUPER_ADMIN,
                  ERoleAccess.OWNER,
                  ERoleAccess.MANAGER,
                  ERoleAccess.ADMIN_PLUS,
                  ERoleAccess.ADMIN,
                  ERoleAccess.RECEPTION,
                ]}
              >
                <GuidelinesPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={ERouterPaths.LOGIN}
            element={
              <PublicOutlet>
                <LoginPage />
              </PublicOutlet>
            }
          />
          <Route
            path={ERouterPaths.FORGOT_PASSWORD}
            element={<ForgotPasswordPage />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route
          path={`${ERouterPaths.RESET_PASSWORD}/:token`}
          element={<ResetPasswordPage />}
        />
        <Route
          path={`${ERouterPaths.VALIDATE_PERSON}/:token`}
          element={<ValidatePersonPage />}
        />
        <Route path={ERouterPaths.JOIN} element={<JoinPage />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};
