import { gql } from "@apollo/client";

export interface ILanguageFlag {
  id: string;
  isoLanguageCode: string;
  isoCountryCode: string;
  locale?: {
    id: string;
    name: string;
  };
}

export interface ILanguageFlagsData {
  allLanguageFlags: ILanguageFlag[];
}

export const ALL_LANGUAGE_FLAGS = gql`
  query allLanguageFlags {
    allLanguageFlags {
      id
      isoLanguageCode
      isoCountryCode
      locale(lang: "en-GB") {
        id
        name
      }
    }
  }
`;
