import { endOfMonth, endOfToday, startOfMonth } from "date-fns";
import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  startDateSubject: {
    inputProps: {
      label: "Start date",
      name: "startDateSubject",
      placeholder: "",
      required: true,
    },
  },
  endDateSubject: {
    inputProps: {
      label: "End date",
      name: "endDateSubject",
      placeholder: "",
      required: true,
    },
  },
  startDateInteractive: {
    inputProps: {
      label: "Start date",
      name: "startDateInteractive",
      placeholder: "",
      required: true,
    },
  },
  endDateInteractive: {
    inputProps: {
      label: "End date",
      name: "endDateInteractive",
      placeholder: "",
      required: true,
    },
  },
  tourIdInteractive: {
    inputProps: {
      label: "Interactive tour",
      name: "tourIdInteractive",
      placeholder: "",
      required: true,
    },
  },
  startDateClassic: {
    inputProps: {
      label: "Start date",
      name: "startDateClassic",
      placeholder: "",
      required: true,
    },
  },
  endDateClassic: {
    inputProps: {
      label: "End date",
      name: "endDateClassic",
      placeholder: "",
      required: true,
    },
  },
  tourIdClassic: {
    inputProps: {
      label: "Classic tour",
      name: "tourIdClassic",
      placeholder: "",
      required: true,
    },
  },
});
