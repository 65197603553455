import { gql } from "@apollo/client";

export interface IStatisticsClassicTourData {
  getStatisticsClassicTour: number;
}

export interface IStatisticsClassicTourVars {
  endDate: Date;
  startDate: Date;
  classicTourId: number;
}

export const GET_STATISTICS_CLASSIC_TOUR = gql`
  query getStatisticsClassicTour(
    $startDate: DateTime!
    $endDate: DateTime!
    $classicTourId: ID!
  ) {
    getStatisticsClassicTour(
      startDate: $startDate
      endDate: $endDate
      classicTourId: $classicTourId
    )
  }
`;
