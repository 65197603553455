import { gql } from "@apollo/client";

export interface IStatisticsInteractiveTourData {
  getStatisticsInteractiveTour: number;
}

export interface IStatisticsInteractiveTourVars {
  endDate: Date;
  startDate: Date;
  interactiveTourId: number;
}

export const GET_STATISTICS_INTERACTIVE_TOUR = gql`
  query getStatisticsInteractiveTour(
    $startDate: DateTime!
    $endDate: DateTime!
    $interactiveTourId: ID!
  ) {
    getStatisticsInteractiveTour(
      startDate: $startDate
      endDate: $endDate
      interactiveTourId: $interactiveTourId
    )
  }
`;
